import React from "react"
import { 
    FacebookShareButton, 
    FacebookIcon, 
    LineShareButton, 
    LineIcon,
    LinkedinShareButton, 
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share';

export default function ShareSns ({ articleUrl, articleTitle }) {
    return (
        <div className="sns-btn_wrap sns-btn_wrap_s">
            <div>
                <FacebookShareButton url={articleUrl}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
        
                <LineShareButton url={articleUrl}>
                    <LineIcon size={32} round />
                </LineShareButton>
        
                <LinkedinShareButton url={articleUrl}>
                    <LinkedinIcon title={articleTitle} size={32} round />
                </LinkedinShareButton>
        
                <TwitterShareButton title={articleTitle} via="wakuwakustudy" url={articleUrl}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </div>
        </div>
    )
}