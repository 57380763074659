import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import ShareSns from "../components/sharesns"

export default function MarkdownPost({ data }) {
  const post = data.markdownRemark
  const description = post.excerpt.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
  const metaimage = `https://wakuwakustudyworld.co.jp${post.frontmatter.thumbnail}`
  const windowUrl = (typeof window !== 'undefined' && window.location.href) ? window.location.href : '';
  return  (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta property="og:site_name" content={post.frontmatter.title} />
        <meta property="og:image" content={metaimage} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={post.frontmatter.title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={metaimage} />
      </Helmet>
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="keni-section_wrap">
                <article className="keni-section">
                  <section className="keni-section">
                      <div className="contents">
                          <h2>{post.frontmatter.title}</h2>
                          <img src={post.frontmatter.thumbnail} />
                          <div className="news-list_item_date">
                            <time datetime={post.frontmatter.date} itemprop="datePublished" content={post.frontmatter.date}>
                              {post.frontmatter.dateJP}
                            </time>
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: post.html }} />
                      </div>
                  </section>
                  <ShareSns articleUrl={windowUrl} articleTitle={post.frontmatter.title} />
                </article>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
    )
}

export const query = graphql`
    query BlogPostQuery($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
            html
            frontmatter {
                title
                dateJP:date(formatString: "YYYY年MM月DD日")
                date(formatString: "YYYY-MM-DD")
                thumbnail
            }
            excerpt(format: MARKDOWN, pruneLength: 50)
        }
    }
`